// import Vue from 'vue'
import { Component } from "vue-property-decorator";
import { ChangePasswordModel, UserLoggedInfoModel } from "@/models/userModels";
import { mapActions } from "vuex";
import baseContextComponent from "@/components/baseContextComponent";
import api from "@/services/api";

@Component({
	methods: { ...mapActions(["loginUser"]) }
})
export default class changePasswordPage extends baseContextComponent {
	isLoading: boolean = false;
	user: ChangePasswordModel = new ChangePasswordModel();
	loginUser!: (token: any) => Promise<UserLoggedInfoModel>;
	passwordRules = [(v: string | null) => !!v || "Campo obbligatorio"];

	checkForm(e: any) {
		var instance = this;
		this.user.token = this.$route.query.token as string;
		e.preventDefault();
		instance.isLoading = true;
		api.doChangePassword(instance.user)
			.then(token => {
				instance.isLoading = false;
				let path = "/";
				instance.loginUser(token).then(() => {
					instance.$router.push({ path: path as string });
				});
			}).finally(() => instance.isLoading = false);
	}
	get headerText(): string {
		return this.$appConfig.loginPage.headerText;
	}
	get htmlText(): string {
		// return this.$appConfig.loginPage.htmlText;
		return "";
	}
}
